import { default as indexOEym9L6qb4Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexJ61j7kMYHlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/index.ts?macro=true";
import { default as PageFiltersx0o8dZhgnLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionCategoriese6EaLLH5Q6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionCategories.vue?macro=true";
import { default as SectionCollectionListZ9a05eJ8iwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionCollectionList.vue?macro=true";
import { default as SectionFiltersenxH6OdTwzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionFilters.vue?macro=true";
import { default as SectionGenresyfHsRUGZh4Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMoviesm5DCxxoPtUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularIeRKDujHdTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSeries5YvTyZBnE5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFiltersZQECjhkxdRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenresDdnX6d9wt2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_939TUlY7iCeiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/genre/[slug].vue?macro=true";
import { default as indexnp2nGR6Gn1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93u4uh9j84bPMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/collections/[slug].vue?macro=true";
import { default as indexKXigDaHGTOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/collections/index.vue?macro=true";
import { default as copyrightbq2yiIItkgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/copyright.vue?macro=true";
import { default as _91id_93VBljWElgRjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/crew/[id].vue?macro=true";
import { default as indexqpwNgbI5RBMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/faq/index.vue?macro=true";
import { default as all3EGEEdUwySMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/all.vue?macro=true";
import { default as SectionBoughtbhf4szkjNRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinue3LkFIswkozMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavoritesmZt8Iseq5IMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionViewedJQfXEHl1RRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continueTqjk2OjQZvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/continue.vue?macro=true";
import { default as indexrd30jDjrfiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/index.vue?macro=true";
import { default as purchasedaJTXEGKiiAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/purchased.vue?macro=true";
import { default as viewedRGvXga7Sm2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policy0nvzu9GGHgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantagesQnbY2FotEnMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionBannerdFAW4IXGzvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionBanner.vue?macro=true";
import { default as SectionCollectionsx2Ov0nPTjQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinueqENDDm15NCMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilmsNCTRnzNR9kMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenresp4MoA9V4NPMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeaderykihymgESSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopularEYkwC711R6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremiersc3BE4zl45oMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeriesyiJoRqPeHYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptionsl4eDfcoKmSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarizeJUfvAKRk6XMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionTopMovieslef7g0CGJLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionTopMovies.vue?macro=true";
import { default as SectionUpcomingcTFMsrywZWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as IndexBbDOD6m4aGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/Index.vue?macro=true";
import { default as _91slug_934MX6W6ozNxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstall4F25j1LXJ1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstallw2DLI4Ldp7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as types98a4sZLAX1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/install/components/types.ts?macro=true";
import { default as useInstallmgWH01mylbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/install/components/useInstall.ts?macro=true";
import { default as filmcrewA0OPthClAvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/[slug]/filmcrew.vue?macro=true";
import { default as indexQrQjWnra72Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/[slug]/index.vue?macro=true";
import { default as MovieCollectionsPESUEWEi59Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrewwlwYqhXlBYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescriptiontWyN0o8ksHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeaderH4mBJxGVIuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNoticeIHj3gE84F3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeletonDaMvU9BN1IMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButton6cDkjiVu98Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelatedwRaJNrWkhPMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeriesvH7J2x5DecMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailerstMldB0k6brMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptionsH4MiKrOzP4Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_93f8rjaqmZRrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/news/[slug].vue?macro=true";
import { default as indexj4ZFZg0HOPMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/news/index.vue?macro=true";
import { default as novelty_45filmsLEnFg4etWdMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45seriesVXEM2qp4PvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreement3LjeJ09T5rMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyrightqv50Bsb6vFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holderMSjiipbCbaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/policy-holder.vue?macro=true";
import { default as policy_45private0C2yPP5z07Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/policy-private.vue?macro=true";
import { default as policy_45user3RzrJiLp5NMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/policy-user.vue?macro=true";
import { default as premiers0JdF48585tMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/premiers.vue?macro=true";
import { default as private_45policyTjikcKSUUgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/private-policy.vue?macro=true";
import { default as indexY8GUjGVZ2YMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModalhEC0QdFRRaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfile1CvDBxiaghMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModalhGl7qcYYbOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VRemoveUserDatat9k0glKQTJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/modal/VRemoveUserData.vue?macro=true";
import { default as VResumeSubscriptionSuccessModalORsE0QGDz2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferrerdumQeE5NWLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validationEVtE8leCGyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistoryeQBLrCOmfNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardjkaR7r4cFqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettings1Lg8KkG7UYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPayments2wevBX1N70Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabsbJYe2Cn6CtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgramktxBYcf2sNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptionsaKuAwHm1vKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as indexPQpvDfvz3vMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/devices/index.vue?macro=true";
import { default as indexlBdXDs2PPSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/index.vue?macro=true";
import { default as indexPPKO2zRV5nMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/notifications/index.vue?macro=true";
import { default as indextBQYWE03xHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/payments/index.vue?macro=true";
import { default as indexV08uJb5H4KMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/settings/index.vue?macro=true";
import { default as indexhbTPVfUSK6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/user/index.vue?macro=true";
import { default as resultEHJQMtd3j5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/result.vue?macro=true";
import { default as _91slug_93ZB0YFbqjqKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/similar/[slug].vue?macro=true";
import { default as indexijuFJyiMAvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/sports/channels/index.vue?macro=true";
import { default as indexFsMtHr4fQyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/sports/index.vue?macro=true";
import { default as SectionAds09sTqnWVUzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContentnDyRh0ifLWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDevicesbtcyvUZgH5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownloadzkF3YzELqTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeadermAbTlJZefZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptions2ppLW0S55mMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceoverdMXNviywHUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as indexrcV7QdeiYGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/index.vue?macro=true";
import { default as termsw3hf5Y6mhRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/terms.vue?macro=true";
import { default as upcomingosNoNS1FvwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCategories",
    path: "/browse/components/SectionCategories",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionCategories.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCollectionList",
    path: "/browse/components/SectionCollectionList",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionCollectionList.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionFilters",
    path: "/browse/components/SectionFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionBanner",
    path: "/home/components/SectionBanner",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionBanner.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionTopMovies",
    path: "/home/components/SectionTopMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionTopMovies.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug-filmcrew",
    path: "/movie/:slug()/filmcrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/[slug]/filmcrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indexY8GUjGVZ2YMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRemoveUserData",
    path: "/profile/components/modal/VRemoveUserData",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/modal/VRemoveUserData.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: indexPQpvDfvz3vMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexlBdXDs2PPSMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: indexPPKO2zRV5nMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: indextBQYWE03xHMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: indexV08uJb5H4KMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-user",
    path: "/profile/user",
    meta: indexhbTPVfUSK6Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/profile/user/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: indexFsMtHr4fQyMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-441190/src/pages/upcoming.vue").then(m => m.default || m)
  }
]